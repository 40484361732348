import React, { useContext } from 'react'
import { InitializeContext } from '../../App';
import './Portfolio.css';
import Works from './Works';

export default function Portfolio() {
  const { portfolio } = useContext(InitializeContext);
  return (
    <section ref={portfolio} className="work section">
      <h2 className="section__title">Portfolio</h2>
      <span className="section__subtitle">Most recent works</span>
      <Works />
    </section>
  )
}
