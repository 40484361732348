import React, { useContext } from 'react'
import './Home.css';
import Social from './Social';
import Data from './Data';
import ScrollDown from './ScrollDown';
import { InitializeContext } from '../../App';

export default function Home() {
          const { home } = useContext(InitializeContext);
          return (
                    <section ref={home} className="home section">
                              <div className="home__container container grid">
                                        <div className="home__content grid">
                                                  <Social />
                                                  <div className="home__img"></div>

                                                  <Data />
                                        </div>
                                        <ScrollDown />
                              </div>
                    </section>
          )
}
