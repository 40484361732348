import React from 'react'

export default function Social() {
          return (
                    <div className="home__social">
                              <a href="https://facebook.com/toufiqhasankiron" className="home__social-icon" target="_blank" rel="noreferrer">
                                        <i className="bx bxl-facebook-circle"></i>
                              </a>
                              {/* <a href="https://instagram.com/toufiq_hasan_kiron" className="home__social-icon" target="_blank" rel="noreferrer">
                                        <i className="uil uil-instagram"></i>
                              </a> */}
                              {/* <a href="https://twitter.com/ToufiqHasan0" className="home__social-icon" target="_blank" rel="noreferrer">
                                        <i className="uil uil-twitter-alt"></i>
                              </a> */}
                              <a href="https://github.com/kiron0" className="home__social-icon" target="_blank" rel="noreferrer">
                                        <i className="uil uil-github-alt"></i>
                              </a>
                              <a href="https://linkedin.com/in/toufiq-hasan-kiron" className="home__social-icon" target="_blank" rel="noreferrer">
                                        <i className="uil uil-linkedin"></i>
                              </a>
                    </div>
          )
}
