import './App.css';
import { createContext, useRef } from "react";
import { Toaster } from "react-hot-toast";
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import ScrollUp from './Components/ScrollUp/ScrollUp';
import Skills from './Components/Skills/Skills';
import Testimonials from './Components/Testimonials/Testimonials';
import Services from './Components/Services/Services';
import Portfolio from './Components/Portfolio/Portfolio';
import Qualification from './Components/Qualification/Qualification';

export const InitializeContext = createContext(null as any);

function App() {
  const home = useRef(null);
  const about = useRef(null);
  const skills = useRef(null);
  const services = useRef(null);
  const portfolio = useRef(null);
  const contact = useRef(null);
  const testimonial = useRef(null);

  const scrollToSection = (elementRef: any) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    })
  }

  return (
    <>
      <InitializeContext.Provider value={{ home, about, skills, services, portfolio, contact, testimonial, scrollToSection }}>
        <Header />
        <main className="main">
          <Home />
          <About />
          <Skills />
          <Services />
          <Qualification />
          <Portfolio />
          <Testimonials />
          <Contact />
        </main>
        <Footer />
        <ScrollUp />
        <Toaster />
      </InitializeContext.Provider>
    </>
  );
}

export default App;
