import Work1 from '../../Assets/work1.jpg';
import Work2 from '../../Assets/work2.jpg';
import Work3 from '../../Assets/work3.jpg';
import Work4 from '../../Assets/work4.jpg';
import Work5 from '../../Assets/work5.jpg';

export const projectsData = [
          {
                    id: 1,
                    image: Work1,
                    title: "Web design",
                    category: "web",
                    live: "https://www.google.com",
                    client: "https://www.google.com",
                    server: "https://www.google.com",
          },
          {
                    id: 2,
                    image: Work2,
                    title: "App mobile",
                    category: "app",
                    live: "https://www.google.com",
                    client: "https://www.google.com",
          },
          {
                    id: 3,
                    image: Work3,
                    title: "Brand design",
                    category: "design",
                    live: "https://www.google.com",
                    client: "https://www.google.com",
                    server: "https://www.google.com",
          },
          {
                    id: 4,
                    image: Work4,
                    title: "App mobile",
                    category: "app",
                    live: "https://www.google.com",
                    client: "https://www.google.com",
          },
          {
                    id: 5,
                    image: Work5,
                    title: "Web design",
                    category: "web",
                    live: "https://www.google.com",
                    client: "https://www.google.com",
                    server: "https://www.google.com",
          },
];

export const projectsNav = [
          {
                    name: "all",
          },
          {
                    name: "web",
          },
          {
                    name: "app",
          },
          {
                    name: "design",
          }
];