import Image1 from '../../Assets/testimonial1.png';
import Image2 from '../../Assets/testimonial2.png';
import Image3 from '../../Assets/testimonial3.png';

export const Data = [
          {
                    id: 1,
                    image: Image1,
                    title: "Jhon Doe",
                    description:
                              "A really good job, all aspects of the project were followed step by step and with good results.",
          },
          {
                    id: 2,
                    image: Image2,
                    title: "Harry Clinton",
                    description:
                              "A really good job, all aspects of the project were followed step by step and with good results.",
          },
          {
                    id: 3,
                    image: Image3,
                    title: "Sara Cill",
                    description:
                              "A really good job, all aspects of the project were followed step by step and with good results.",
          },
];