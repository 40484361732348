import React from 'react'
import "./ScrollUp.css";

export default function ScrollUp() {
          /*=============== Toggle Menu ===============*/

          const scrollVisible = () => {
                    const scrollUp = document.querySelector(".scrollUp");
                    if (scrollUp) {
                              if (window.scrollY >= 560) {
                                        scrollUp.classList.add("show-scroll");
                              } else {
                                        scrollUp.classList.remove("show-scroll");
                              }
                    }
          };

          const scrollToTop = () => {
                    window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                    });
          };

          window.addEventListener("scroll", scrollVisible);
          return (
                    <span className="scrollUp" onClick={scrollToTop}>
                              <i className="uil uil-arrow-up scrollUp__icon"></i>
                    </span>
          )
}
